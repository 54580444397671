.rhap_container {
    background: #000 !important;
}

.rhap_current-time, .rhap_time {
    color: #CCC !important;
}

.rhap_progress-bar {
    background: #CCC !important;
}

.rhap_header {
    color: #CCC;
    text-align: center;
}

@media only screen and (max-width: 480px) {
    div#apple-music-logo {
        background-image: url("../public/images/logo/apple/music/icons/black-and-white/Apple_Music_Badge_wht_sm_091917.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        width: 20px;
        height: 20px;
    }
}

@media only screen and (min-width: 481px) {
    div#apple-music-logo {
        background-image: url("../public/images/logo/apple/music/icons/black-and-white/Apple_Music_Badge_wht_sm_091917.svg");
        background-repeat: no-repeat;
        background-size: 100px;
        width: 100px;
        height: 30px;
    }
}


/*
images/logo/apple/music/icons/black-and-white/Apple_Music_Listen_on_Lockup_all-wht_100617.svg
 */